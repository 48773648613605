<template>
  <div class="container tests">
    <Breadcrumb />
    <div>
      <div class="title">
        <h2 class="text-success">Tests List</h2>
        <a href="/tests/grading" style="color:white!important;">
          <el-button type="primary" size="small">
            <i class="fa fa-superscript" />
            {{ $t("greet.grading") }}
          </el-button>
        </a>
      </div>
      <el-divider></el-divider>
      <el-radio-group v-model="type" @change="changeType">
        <el-radio-button label="SAT">SAT</el-radio-button>
        <el-radio-button label="ACT">ACT</el-radio-button>
        <el-radio-button label="TOEFL">TOEFL</el-radio-button>
      </el-radio-group>
      <el-divider></el-divider>
      <a
        :href="'/' + type.toLowerCase() + '/answers?create=1'"
        style="color:white!important; margin-right: 12px"
      >
        <el-button type="primary" v-if="type === 'SAT' || type === 'ACT'">
          <i class="el-icon-plus"></i>
          Add New Test
        </el-button>
      </a>
      <div class="search">
        <el-input
          style="width:100%;margin-bottom:30px"
          placeholder="請輸入關鍵字"
          v-model="test"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getTests"
          ></el-button>
        </el-input>
      </div>
      <div v-if="type === 'SAT' || type === 'ACT'">
        <el-table
          :data="tests"
          border
          style="width: 100%;margin-bottom:30px"
          class="tests-list"
          height="500"
        >
          <el-table-column label="Test Name" width="200">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.user_exam_id > 0"
                :to="{
                  name: 'TestResult',
                  query: {
                    user_exam_id: scope.row.user_exam_id
                  },
                  params: {
                    testType: type.toLowerCase()
                  }
                }"
                tag="a"
                target="_blank"
              >
                {{ scope.row.full_name }}
              </router-link>
              <span v-else>
                {{ scope.row.full_name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Test ID" width="200">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.user_exam_id > 0"
                :to="{
                  name: 'TestResult',
                  query: {
                    user_exam_id: scope.row.user_exam_id
                  },
                  params: {
                    testType: type.toLowerCase()
                  }
                }"
                tag="a"
                target="_blank"
              >
                {{ scope.row.title }}
              </router-link>
              <span v-else>
                {{ scope.row.title }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Last Test">
            <el-table-column label="Student" width="">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'TestResult',
                    query: {
                      user_exam_id: scope.row.user_exam_id
                    },
                    params: {
                      testType: type.toLowerCase()
                    }
                  }"
                  tag="a"
                  v-if="scope.row.user_exam_id > 0"
                  target="_blank"
                >
                  {{
                    userMethods.displayName(
                      scope.row.first_name,
                      scope.row.last_name
                    )
                  }}
                </router-link>
                <span v-else>
                  N/A
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Date" width="">
              <template slot-scope="scope">
                <span v-if="scope.row.user_exam_id > 0">
                  <router-link
                    :to="{
                      name: 'TestResult',
                      query: {
                        user_exam_id: scope.row.user_exam_id
                      },
                      params: {
                        testType: type.toLowerCase()
                      }
                    }"
                    tag="a"
                    v-if="scope.row.user_exam_id > 0"
                    target="_blank"
                  >
                    {{ instant.formatDateTime(scope.row.date) }}
                  </router-link>
                </span>
                <span v-else>
                  N/A
                </span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="Students" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.student_test_count > 0">
                <a
                  :href="
                    '/tests/list?type=' +
                      type.toLowerCase() +
                      '&exam_id=' +
                      scope.row.exam_id
                  "
                  target="_blank"
                >
                  <b>{{ scope.row.student_test_count }}</b>
                </a>
              </span>
              <span v-else>
                N/A
              </span>
            </template>
          </el-table-column>
          <el-table-column label="File" width="70">
            <template slot-scope="scope">
              <a
                :href="scope.row.test_url"
                v-if="
                  scope.row.test_url !== null &&
                    scope.row.test_url.indexOf('https://') !== -1
                "
                target="_blank"
              >
                <i class="fa fa-file-pdf"></i>
              </a>
              <span v-else>
                N/A
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Analysis" width="100">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.exam_id && scope.row.student_test_count > 0"
                :to="{
                  path: `/analysis/${type.toLowerCase()}`,
                  query: {
                    type: type,
                    test: scope.row[type.toLowerCase() + '_id']
                  }
                }"
                tag="a"
                target="_blank"
                style="margin-right:5px"
              >
                <i class="fas fa-chart-bar"></i>
              </router-link>
              <span v-else>
                N/A
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Action" width="150">
            <template slot-scope="scope">
              <a
                class="action-icon"
                :href="
                  '/' +
                    type.toLowerCase() +
                    '/answers?exam_id=' +
                    scope.row[type.toLowerCase() + '_id']
                "
              >
                <i class="fa fa-eye"></i>
              </a>
              <a
                class="action-icon"
                :href="
                  '/' +
                    type.toLowerCase() +
                    '/answers?exam_id=' +
                    scope.row[type.toLowerCase() + '_id'] +
                    '&can_edit=1'
                "
              >
                <i class="fa fa-edit"></i>
              </a>
              <el-tooltip
                class="item"
                content="批改"
                placement="top"
                popper-class="tooltipColor"
              >
                <a
                  class="action-icon"
                  :href="`/tests/grading/${testType}?keyboard=2&exam_id=${scope.row.exam_id}`"
                >
                  <i class="fa fa-superscript"></i>
                </a>
              </el-tooltip>
              <el-tooltip
                class="item"
                content="寄考卷"
                placement="top"
                popper-class="tooltipColor"
              >
                <a
                  class="action-icon"
                  :href="`/tests/grading/addstudents?type=${testType}&exam_id=${scope.row.exam_id}`"
                >
                  <i class="far fa-envelope" />
                </a>
              </el-tooltip>
              <span v-if="!(scope.row.student_test_count > 0)" @click="removeAnswers(scope.row[type.toLowerCase() + '_id'])">
                <i class="fas fa-trash-alt warning-icon" />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="type === 'TOEFL'">
        <el-table
          :data="tests"
          border
          style="width: 100%;margin-bottom:30px"
          class="tests-list"
          height="500"
        >
          <el-table-column label="Test Name" width="200">
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column label="Test ID" width="200">
            <template slot-scope="scope">
              <span>
                {{ scope.row.title }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Last Test">
            <el-table-column label="Student" width="">N/A</el-table-column>
            <el-table-column label="Date" width="">N/A</el-table-column>
          </el-table-column>
          <el-table-column label="Students" width="120">N/A</el-table-column>
          <el-table-column label="File" width="80">N/A</el-table-column>
          <el-table-column fixed="right" label="Analysis" width="100">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.id"
                :to="{
                  name: 'AnalysisToefl',
                  query: {
                    type: type,
                    test: scope.row.id
                  }
                }"
                tag="a"
                target="_blank"
              >
                <i class="fas fa-chart-bar"></i>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { instant, user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import testApi from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: "Tests - Ivy-Way Academy"
    };
  },

  components: { Breadcrumb },

  props: [],
  data() {
    return {
      form: {
        type: "SAT",
        test: "",
        keyboard: "1"
      },
      type: "SAT",
      test: "",
      tests: [],
      user_exam_id() {
        return this.$route.query.user_exam_id;
      }
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    testType() {
      const testType = this.$route.query.type;
      return (testType || "SAT").toLowerCase();
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getTests();
  },

  methods: {
    removeAnswers(id) {
      this.$confirm("此操作將永久刪除該考試, 是否繼續?", "提示", {
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          await testApi.removeTest(this.testType, id);
          this.$message({
            message: "已刪除！",
            type: "success"
          });
          this.getTests();
        })
        .catch(() => {
          return false;
        });
    },
    changeType(type) {
      this.routerPush({
        name: "Tests",
        query: {
          type: type
        }
      });
    },
    async getTests() {
      let tests = [];
      let queryString = this.test;
      tests = await testApi.getTestList(this.type,{ keyword:queryString });

      // 调用 callback 返回建议列表的数据
      let results = tests;
      if (this.type === "TOEFL") {
        this.tests = results.toefls;
      } else {
        this.tests = results;
        results = queryString
          ? tests.filter(this.createFilter(queryString))
          : tests;
      }
    },
    createFilter(queryString) {
      return tests => {
        return (
          tests.full_name.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0 ||
          tests.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    viewAnswer(type, item) {
      let urlName = "SatAnswers";
      if (this.type == "SAT") {
        urlName = "SatAnswers";
      } else if (this.type == "ACT") {
        urlName = "ActAnswers";
      }
      this.routerPush({
        name: urlName,
        query: {
          exam_id: item[this.type.toLowerCase() + "_id"],
          can_edit: type
        }
      });
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}

.search {
  margin-top: 20px;
}

.title {
  display: flex;
  align-items: center;
}

.title > h2 {
  margin-bottom: 0px;
}

.title > a {
  margin: 0px 8px;
}
</style>
